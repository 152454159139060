<template>
  <div class="relative z-10">
    <div class="absolute top-0 w-full h-full">
      <div class="sticky top-0 h-screen overflow-hidden">
        <div class="absolute -top-40 -right-40 lg:-top-[540px] lg:-right-[540px] rounded-full blur-3xl md:blur-[100px] w-80 h-80 lg:w-[1080px] lg:h-[1080px] duration-150"
             :class="[`bg-${color}`]"
             :style="{
               opacity: opacity,
               transform: 'translate3d(0,0,0)'
             }"
        />
      </div>
    </div>
    <ScrollingCards :copy="copy"
                    :gradient-color="color"
                    :cards="cards"
                    class="z-10"
    />
    <div class="relative">
      <section ref="container"
               class="sticky top-0 h-[600vh] lg:h-[800vh]"
      >
        <div class="sticky top-0 h-screen overflow-hidden">
          <Type3d :title="type3d.title"
                  :copy="type3d.copy"
                  :trigger="progress / sectionTresholds[1]"
                  class="absolute inset-0 transition duration-300"
                  :class="isSectionVisible(0) ? 'opacity-100 z-10' : 'opacity-0'"
          />

          <GravityTags :title="gravityTags.title"
                       :copy="gravityTags.copy"
                       :tags="gravityTags.tags"
                       :trigger="(progress - sectionTresholds[1]) / (sectionTresholds[2] - sectionTresholds[1])"
                       class="absolute inset-0 transition duration-300"
                       :class="isSectionVisible(1) ? 'opacity-100 z-10' : 'opacity-0'"
          />
          <SlidingImages :trigger="(progress - sectionTresholds[2]) / (sectionTresholds[3] - sectionTresholds[2])"
                         :title="images.title"
                         :images="images.items"
                         class="absolute inset-0 transition duration-300"
                         :class="isSectionVisible(2) ? 'opacity-100' : 'opacity-0'"
          />
          <GradientCircle :title="gradientTitle"
                          :trigger="(progress - sectionTresholds[3]) / (sectionTresholds[4] - sectionTresholds[3])"
                          :color="color"
                          class="transition duration-300"
                          :class="isSectionVisible(3) ? 'opacity-100' : 'opacity-0'"
          />
        </div>
      </section>
      <div class="absolute bottom-0 z-20 w-full overflow-hidden bg-white rounded-t-3xl"
           :style="{ transform: `scale(${scale})` }"
      >
        <StoriesContainer :background-color="storiesContainer.backgroundColor"
                          :title="storiesContainer.title"
                          :subtitle="storiesContainer.subtitle"
                          :sections="storiesContainer.sections"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { scroll } from 'motion';
import { onMounted, ref } from 'vue';
import GravityTags from './scrolling-story/gravity-tags.vue';
import SlidingImages from './scrolling-story/sliding-images.vue';
import GradientCircle from './scrolling-story/gradient-circle.vue';
import Type3d from './scrolling-story/type3d.vue';
import ScrollingCards from './scrolling-cards.vue';
import StoriesContainer from './stories-container.vue';

interface scrollingStory {
  color: string;
  copy: string;
  cards: {
    title: string;
    copy: string;
    backgroundGradient: string;
    backgroundImage?: string
  }[];
  type3d: {
    title: string;
    copy: string;
  };
  gravityTags: {
    title: string;
    copy: string;
    tags: {
      text: string;
      color: string;
    }[];
  };
  images: {
    title: string;
    items: {
      src: string;
      alt: string;
    }[];
  };
  gradientTitle: string;
  storiesContainer: {
    backgroundColor: string;
    title: string;
    subtitle: string;
    sections: any[];
  };
}

const opacity = ref(0);
const container = ref();
const progress = ref(0);
const scale = ref(0.8);

const sectionTresholds = [0, 0.2, 0.35, 0.65, 1];

const isSectionVisible = (i: number) => {
  if (progress.value >= sectionTresholds[i] && progress.value < sectionTresholds[i + 1]) return true;
  return false;
};

defineProps<scrollingStory>();

onMounted(() => {
  if (!container.value) return;
  scroll(
    ({ y }) => {
      opacity.value = Math.max(0, 1 - Math.min(1, y.progress / sectionTresholds[1]));
      scale.value = Math.min(1, Math.max(0.8, 0.8 + ((y.progress - 0.81) / (0.88 - 0.81)) * 0.2));

      progress.value = y.progress;
    },
    {
      target: container.value,
      offset: ['start end', 'end end']
    }
  );
});
</script>
